import React from "react";
import styled from "styled-components";
import Box from "@material-ui/core/Box";
import WrapTypo from "../components/WrapTypo";

const RootBox = styled(Box)`
  a {
    position: relative;
    display: inline-block;
    text-decoration: none;
  }
  a::after {
    position: absolute;
    bottom: 2px;
    left: 0;
    content: "";
    width: 100%;
    height: 1px;
    background: #818181;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
  }
  a:hover {
    color: #fff;
  }
  a:hover::after {
    bottom: -2px;
    opacity: 1;
    visibility: visible;
    background-color: #818181;
  }
`;
const HoverWrapTypo = styled(WrapTypo)`
  :hover {
    color: #919191;
  }
`;

const AnimeLink = props => {
  return (
    <>
      <RootBox {...props}>
        <a href={props.link}>
          <HoverWrapTypo
            ls="5px"
            lh="250%"
            fs={props.fs}
            color={props.color}
            style={{ minWidth: "160px" }}
          >
            {props.text}
          </HoverWrapTypo>
        </a>
      </RootBox>
    </>
  );
};

export default AnimeLink;
