import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import WrapTypo from "../components/WrapTypo";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FooterAnimeLink from "../components/footerAnimeLink";

function Footer() {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));

  const data = useStaticQuery(graphql`
    query {
      tw_logo: file(relativePath: { eq: "tw_logo3.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bar: file(relativePath: { eq: "bar.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fb_logo: file(relativePath: { eq: "Facebook_Logo2.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      in_logo: file(relativePath: { eq: "Instagram_logo2.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      note_logo: file(relativePath: { eq: "note_logo3.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <Box bgcolor="#323544" position="relative">
        <Hidden xsDown>
          <Box
            width="1px"
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              margin: "auto",
            }}
          >
            <Img fluid={data.bar.childImageSharp.fluid} />
          </Box>
        </Hidden>
        <Box style={isXs ? { padding: "73px 0" } : { padding: "96px 0" }}>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
          >
            <Grid item xs={12} sm={6}>
              <Box
                textAlign="center"
                style={isXs ? { margin: "0 0 73px" } : { margin: "0 0 32px" }}
              >
                <FooterMenu text="HAYAFUJI" link="/" marginBottom={4} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FooterMenu
                text="わたしたちの信念"
                link="/concept"
                marginBottom={4}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row-reverse"
            justify="space-around"
            alignItems="center"
          >
            <Grid item xs={12} sm={6}>
              <FooterMenu text="施工事例一覧" link="/" marginBottom={4} />
              <FooterMenu
                text="ご利用の流れ"
                link="/useageflow"
                marginBottom={4}
              />

              <Box
                textAlign="center"
                style={isXs ? { margin: "0 0 73px" } : { margin: "0 0 32px" }}
              >
                <FooterAnimeLink
                  color="#ffffff"
                  text="会社概要"
                  link="/company"
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Hidden xsDown>
                <Box textAlign="center" mb={4}>
                  <WrapTypo color="#323544"></WrapTypo>
                </Box>
              </Hidden>
              <FooterMenu
                text="同志の募集"
                link="/recruiting"
                marginBottom={4}
              />
              <Box
                textAlign="center"
                style={isXs ? { margin: "0" } : { margin: "0 0 32px" }}
              >
                <FooterAnimeLink
                  color="#ffffff"
                  text="お問い合わせ"
                  link="/contacts"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

const FooterMenu = ({ text, link, marginBottom }) => {
  return (
    <>
      <Box textAlign="center" mb={marginBottom}>
        <FooterAnimeLink color="#ffffff" text={text} link={link} />
      </Box>
    </>
  );
};

export default Footer;
