import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import WrapTypo from "../components/WrapTypo";
import Drawer from "@material-ui/core/Drawer";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import AOS from "aos";
import "aos/dist/aos.css";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import AnimeLink from "./hayafujiAnimeLink";

const useStyles = makeStyles({
  BackdropProps: {
    background: "rgba(0,0,0,0)",
  },
  paper: {
    backgroundColor: "#E8E7E7",
  },
  list: {
    width: 448,
  },
  fullList: {
    width: "100vw",
  },
});

const HoverChangeColorGrey = styled(Box)`
  :hover {
    filter: invert(50%) sepia(0%) saturate(11%) hue-rotate(143deg)
      brightness(101%) contrast(93%);
  }
`;

const HoverChangeColorGreyText = styled(WrapTypo)`
  :hover {
    color: #818181;
  }
`;

const Header = ({ isBlack }) => {
  if (typeof document !== `undefined`) {
    AOS.init({
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      delay: 600, // values from 0 to 3000, with step 50ms
      duration: 1000, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false,
      anchorPlacement: "bottom-bottom",
    });
  }

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));

  const [isOpen, setOpen] = useState(false);
  const sp_mtSize = "40px";
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      menuBar: file(relativePath: { eq: "hmenu.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      close: file(relativePath: { eq: "hclose.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      blackMenuBar: file(relativePath: { eq: "blackMenu.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const classes = useStyles();
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: isXs,
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      zIndex="9999"
      style={isXs ? { paddingTop: "24px" } : { paddingTop: "54px" }}
    >
      <Box width="80%" mb={10}>
        <Grid container justify="flex-end">
          <Grid item>
            <Button onClick={toggleDrawer("right", false)}>
              <Box style={{ width: "30px" }}>
                <Img fluid={data.close.childImageSharp.fluid} />
              </Box>
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box textAlign="center" mb={10}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          spacing={4}
        >
          <Grid item data-aos="fade-left" data-aos-duration="250">
            {/* <LinkText link="/" text="HAYAFUJIの信念" /> */}
            <AnimeLink link="/concept" text="わたしたちの信念" />
          </Grid>
          <Grid item data-aos="fade-left" data-aos-duration="500">
            <AnimeLink link="/" text="施工事例一覧" />
          </Grid>
          <Grid item data-aos="fade-left" data-aos-duration="750">
            <AnimeLink link="/useageflow" text="ご利用の流れ" />
          </Grid>
          <Grid item data-aos="fade-left" data-aos-duration="1000">
            <AnimeLink link="/company" text="会社概要" />
          </Grid>
        </Grid>
      </Box>
      <Box textAlign="center" mt={10}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          spacing={4}
        >
          <Grid item data-aos="fade-left" data-aos-duration="1250">
            <AnimeLink link="/recruiting" text="同志の募集" />
          </Grid>
          <Grid item data-aos="fade-left" data-aos-duration="1500">
            <AnimeLink link="/contacts" text="お問い合わせ" />
          </Grid>
        </Grid>
      </Box>
    </div>
  );

  return (
    <>
      <Box
        width="100%"
        zIndex="400"
        position="fixed"
        top="0"
        left="0"
        style={isXs ? { paddingTop: "24px" } : { paddingTop: "54px" }}
      >
        <Box width="85%" margin="auto">
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Box>
                <Button>
                  <Link to="/" style={{ color: "#717171" }}>
                    <HoverChangeColorGreyText
                      color={isBlack ? "#000" : "#ffffff"}
                      fs={"20px"}
                      ls="5px"
                    >
                      HAYAFUJI
                    </HoverChangeColorGreyText>
                  </Link>
                </Button>
              </Box>
            </Grid>
            <Grid item>
              <Button onClick={toggleDrawer("right", true)}>
                <HoverChangeColorGrey style={{ width: "32px" }}>
                  {isBlack ? (
                    <Img fluid={data.blackMenuBar.childImageSharp.fluid} />
                  ) : (
                    <Img fluid={data.menuBar.childImageSharp.fluid} />
                  )}
                </HoverChangeColorGrey>
              </Button>
            </Grid>
          </Grid>
        </Box>
        <React.Fragment key="right">
          <Drawer
            ModalProps={{
              BackdropProps: {
                classes: {
                  root: classes.BackdropProps,
                  paper: classes.paper,
                },
              },
            }}
            anchor="right"
            open={state["right"]}
            onClose={toggleDrawer("right", false)}
          >
            {list("right")}
          </Drawer>
        </React.Fragment>
      </Box>
    </>
  );
};

export default Header;
